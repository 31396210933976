.cta-button {
  background: #1b1b22;
  border-radius: 8px;
  box-shadow: 8px 8px #a999b9;
  padding: 8px 12px;
  color: #fff;
}
.commentaries-1 {
  background: #fafafa;
  border-radius: 8px;
  box-shadow: 8px 8px #9fbca6;
  border: 1px solid #000;
  padding: 8px 12px;
  color: #000;
}
.commentaries-2 {
  background: #fafafa;
  border-radius: 8px;
  box-shadow: 8px 8px #c0a0bd;
  border: 1px solid #000;
  padding: 8px 12px;
  color: #000;
}
.commentaries-3 {
  background: #fafafa;
  border-radius: 8px;
  box-shadow: 8px 8px #a999b9;
  border: 1px solid #000;
  padding: 8px 12px;
  color: #000;
}
.dot {
  position: absolute;
  content: ' ';
  bottom: 10px;
  left: 50%;
  height: 10px;
  width: 10px;
  border-radius: 3px;
  background: purple;
}

.video {
  left: 50%;
  transform: translateX(-50%);
  height: 320px;
}
.title:before {
  position: absolute;
  content: ' ';
  z-index: 1;
  width: 200px;
  height: 2px;
  background: #f1ebe3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.title::after {
  position: absolute;
  content: 'Who is Unify for';
  z-index: 2;
  font-size: 20px;
  color: #5f1da0;
  padding: 1px 10px;
  background: #fafafa;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.title-feat-page:before {
  position: absolute;
  content: ' ';
  z-index: 1;
  width: 200px;
  height: 2px;
  background: #f1ebe3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.title-feat-page::after {
  position: absolute;
  content: 'What we offer';
  z-index: 2;
  font-size: 20px;
  color: #5f1da0;
  padding: 1px 10px;
  background: #fafafa;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.title-feat-faq:before {
  position: absolute;
  content: ' ';
  z-index: 1;
  width: 200px;
  height: 2px;
  background: #f1ebe3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.title-feat-faq::after {
  position: absolute;
  content: 'Have Questions?';
  z-index: 2;
  font-size: 20px;
  color: #5f1da0;
  padding: 1px 10px;
  background: #fafafa;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.title-feat:before {
  position: absolute;
  content: ' ';
  z-index: 1;
  width: 150px;
  height: 2px;
  background: rgb(155, 154, 154);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.title-feat::after {
  position: absolute;
  content: 'Features';
  z-index: 2;
  font-size: 20px;
  color: #5f1da0;
  padding: 1px 10px;
  background: #f1ebe3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.u-card {
  background: #e7dfed;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px;
}
.u-card-title-1 {
  width: max-content;
  background: #fff;
  border-radius: 8px;
  box-shadow: 4px 4px #5f5fcc;
  padding: 8px 12px;
  border: 1px solid #000;
  color: #000;
  font-weight: 500;
  font-size: 20px;
  line-height: 140.1%;
}
.u-card-title-2 {
  background: #fff;
  width: max-content;
  border-radius: 8px;
  box-shadow: 4px 4px #95bd9e;
  padding: 8px 12px;
  border: 1px solid #000;
  color: #000;
  font-weight: 500;
  font-size: 20px;
  line-height: 140.1%;
}
.u-card-title-3 {
  background: #fff;
  width: max-content;
  border-radius: 8px;
  box-shadow: 4px 4px #eda3e6;
  padding: 8px 12px;
  border: 1px solid #000;
  color: #000;
  font-weight: 500;
  font-size: 20px;
  line-height: 140.1%;
}
.u-card-title-4 {
  background: #fff;
  width: max-content;
  border-radius: 8px;
  box-shadow: 4px 4px #dbca74;
  padding: 8px 12px;
  border: 1px solid #000;
  color: #000;
  font-weight: 500;
  font-size: 20px;
  line-height: 140.1%;
}
.cost::after {
  position: absolute;
  content: '';
  z-index: 2;
  height: 10px;
  width: 10px;
  border-radius: 3px;
  background: #5f1da0;
  bottom: 15px;
  right: -13px;
}
