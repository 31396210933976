@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

* {
  font-family: 'Jost', sans-serif;
  padding: 0;
  margin: 0;
}
body {
  background: #fafafa;
}

.swiper-button-prev {
  position: fixed !important;
  left: 44% !important;
  top: 93% !important;
}
.swiper .swiper-button-next {
  position: fixed !important;
  right: 44% !important;
  top: 93% !important;
}
.form {
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 768px) {
  .swiper-button-prev {
    left: 40% !important;
  }
  .swiper .swiper-button-next {
    right: 40% !important;
  }
}
